import React from 'react';
import { useState } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';


import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {

        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        // paddingTop: theme.spacing(8),
        // paddingBottom: theme.spacing(8),
        padding: theme.spacing(4),
    },
    title: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),

    },

    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    button: {
        margin: theme.spacing(2),
        marginLeft: theme.spacing(0),
        color: theme.palette.common.white,

    },
}));

function Settings(props) {

    const classes = useStyles();

    const [settings, setSettings] = useState({
        fromDb: true,
        bld: true,
        combi: true,
        fluoro: true,
        impex: true,
    });

    const handleChange = (event) => {
        setSettings({ ...settings, [event.target.name]: event.target.checked });
    };


    const handleBack = () => {
        props.history.push('/');
    };

    const handleSave = () => {
        props.history.push('/');
    };


    // const BrowserHistory = require('react-router/lib/BrowserHistory').default;

    console.log('hi from settings');

    return (
        <div>

            <Paper>
                <IconButton onClick={handleBack} >

                    <ArrowBackIcon />
                </IconButton>

            </Paper>
            <Container maxWidth="sm">

                <Typography variant="h5" color="textPrimary" className={classes.title}>
                    Settings
                    </Typography>
                <Paper square={true} elevation={2} className={classes.paper}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" >Apply settings</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={settings.fromDb} onChange={handleChange} name="fromDb" />}
                                label="Check database for prices"
                            />
                            <Typography color="textSecondary" variant="caption">Read prices from our database for existing supplier pricing</Typography>
                            <FormControlLabel
                                control={<Checkbox checked={settings.bld} onChange={handleChange} name="bld" />}
                                label="BLD pricing"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={settings.combi} onChange={handleChange} name="combi" />}
                                label="Combi-blocks pricing"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={settings.fluoro} onChange={handleChange} name="fluoro" />}
                                label="Flurochem pricing"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={settings.impex} onChange={handleChange} name="impex" />}
                                label="Chem-Impex pricing"
                            />
                        </FormGroup>
                        <FormHelperText>Be careful</FormHelperText>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"

                                className={classes.button}
                                startIcon={<SaveIcon />}
                                onClick={handleSave}
                            >
                                Save
                                </Button>

                        </div>
                    </FormControl>
                </Paper>
            </Container>


        </div>
    )
}

export default Settings;
