const darkReducer = (state = false, action) => {
    switch (action.type) {
        case 'TOGGLE_DARK':
            return state = !state;
        default:
            return state;

    }
}

export default darkReducer;