import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Price from './price.js';
// import Searchform from './searchform.js';
import Moldata from './moldata.js';
import Sales from './sales.js';

import Grid from '@material-ui/core/Grid';

import axios from 'axios';

import '.././App.css';
import { useEffect } from 'react';
import { getToken, removeUserSession, setUserSession } from '.././Utils/Common';

import { useDispatch } from 'react-redux';
import { signIn } from '.././actions';
import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  title: {
    // flexGrow: 1,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },


}));


function Product({ sterm }) {

  const classes = useStyles();

  const dispatch = useDispatch();

  let apiURL = process.env.REACT_APP_API_URL;
  if (apiURL === undefined) {
    apiURL = "https://priceapi.hetcyc.com";
  }


  const [token, setToken] = useState('');
  // const [cas, setCas] = useState('');

  const [moldata, setMoldata] = useState([]);

  const [salesdata, setSalesdata] = useState([]);

  const [bldprice, setBldprice] = useState({
    prices: [],
    supp: '',
    isLoading: false,
  });
  const [combiprice, setCombiprice] = useState({
    prices: [],
    supp: '',
    isLoading: false,
  });
  const [fluoroprice, setFluoroprice] = useState({
    prices: [],
    supp: '',
    isLoading: false,
  });
  const [impexprice, setImpexprice] = useState({
    prices: [],
    supp: '',
    isLoading: false,
  });

  const [progress, setProgress] = useState(true);




  function get_item_details(sterm, token) {
    let url = `/api/get_item?sterm=${sterm}&token=${token}`;
    url = apiURL.concat(url);

    return axios.get(url).then(response => {


      setMoldata(response.data);

      get_sales_hist(response.data.id, token);

      return response.data;
    })


  }

  const get_sales_hist = async (catid, token) => {
    let url = `/api/get_sales_hist/${catid}?token=${token}`;
    url = apiURL.concat(url);

    await axios.get(url,

    ).then(response =>
      response.data).then(data => {

        setSalesdata(data)

      });
  }



  const get_bld_price = async (cas, token) => {
    let url = `/api/pricing/bld/${cas}?dbcheck=X&maxdays=90&token=${token}`;
    url = apiURL.concat(url);
    await axios.get(url,
      { crossdomain: true }

    ).then(response =>
      response.data).then(data => {


        if (Object.keys(data).length !== 0) {
          setBldprice({ prices: data.prices, supp: data.supp_desc, isLoading: false })

        }
        else {
          setBldprice({
            prices: [],
            supp: '',
            isLoading: false,
          });
        }

      });
  }

  const get_combi_price = async (cas, token) => {
    let url = `/api/pricing/combi/${cas}?dbcheck=X&maxdays=90&token=${token}`;
    url = apiURL.concat(url);
    await axios.get(url,

    ).then(response =>
      response.data).then(data => {


        // if (Object.keys(data).length !== 0) {
        if (Object.keys(data).length !== 0) {
          setCombiprice({ prices: data.prices, supp: data.supp_desc, isLoading: false })
        }
        else {
          setCombiprice({
            prices: [],
            supp: '',
            isLoading: false,
          });
        }


      });
  }

  const get_fluoro_price = async (cas, token) => {
    let url = `/api/pricing/fluoro/${cas}?dbcheck=X&maxdays=90&token=${token}`;
    url = apiURL.concat(url);
    await axios.get(url,

    ).then(response =>
      response.data).then(data => {



        // if (Object.keys(data).length !== 0) {
        if (Object.keys(data).length !== 0) {
          setFluoroprice({ prices: data.prices, supp: data.supp_desc, isLoading: false })
        }
        else {
          setFluoroprice({
            prices: [],
            supp: '',
            isLoading: false,
          });
        }

      });
  }

  const get_impex_price = async (cas, token) => {
    let url = `/api/pricing/impex/${cas}?dbcheck=X&maxdays=90&token=${token}`;
    url = apiURL.concat(url);
    await axios.get(url,

    ).then(response =>
      response.data).then(data => {



        // if (Object.keys(data).length !== 0) {
        if (Object.keys(data).length !== 0) {
          setImpexprice({ prices: data.prices, supp: data.supp_desc, isLoading: false })
        }
        else {
          setImpexprice({
            prices: [],
            supp: '',
            isLoading: false,
          });
        }

      });
  }


  const searchItem = (sterm) => {
    // const sterm = { text };

    // setCas('');

    setBldprice({ prices: [], supp: '', isLoading: true })
    setCombiprice({ prices: [], supp: '', isLoading: true })
    setFluoroprice({ prices: [], supp: '', isLoading: true })
    setImpexprice({ prices: [], supp: '', isLoading: true })

    // setting maximum timer for the progress bar in search
    setProgress(true);
    clearTimeout()
    setTimeout(() => {
      setProgress(false);
    }, 30000);


    get_item_details(sterm.text, token).then(data => {

      if (data.cas_no) {

        get_bld_price(data.cas_no, token);
        get_combi_price(data.cas_no, token);
        get_fluoro_price(data.cas_no, token);
        get_impex_price(data.cas_no, token);
      }
      else {
        get_bld_price(sterm.text, token);
        get_combi_price(sterm.text, token);
        get_fluoro_price(sterm.text, token);
        get_impex_price(sterm.text, token);
      }

    });


  }


  useEffect(() => {
    console.log('hi from productEffect');

    const token = getToken();
    if (!token) {
      return;
    }


    let url = `/api/verifytoken?token=${token}`;
    url = apiURL.concat(url);
    axios.get(url).then(response => {
      setUserSession(response.data.token, response.data.user);
      setToken(response.data.token)
      dispatch(signIn());
      if (sterm) {
        searchItem(sterm);
      }

    }).catch(error => {
      removeUserSession();

    });


  }, [sterm]);




  return (
    <>
      <div className="App" >

        <div className={classes.title}>
          <Container>
            <Typography color="textPrimary" variant="h5" gutterBottom={true} >Overview</Typography>
            <Typography color="textSecondary" variant="body1" gutterBottom={true} >View chemical properties, pricing, sales history and competitors pricing</Typography>
          </Container>
        </div>
        {((bldprice.isLoading || combiprice.isLoading || fluoroprice.isLoading || impexprice.isLoading) && progress) ? <LinearProgress /> : null}
        {Object.keys(moldata).length ? <Moldata mol={moldata} token={token} /> : ''}

        {salesdata.length ? <Sales sales={salesdata} /> : ''}
        <Grid container direction="row" justify="space-between" >

          {(bldprice && bldprice.prices.length) ?
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <Price pricedata={bldprice.prices} supp={bldprice.supp} /> </Grid>
            : null}


          {(combiprice && combiprice.prices.length) ?
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <Price pricedata={combiprice.prices} supp={combiprice.supp} />
            </Grid>
            : null}


          {(fluoroprice && fluoroprice.prices.length) ?
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <Price pricedata={fluoroprice.prices} supp={fluoroprice.supp} />
            </Grid>
            : null}


          {(impexprice && impexprice.prices.length) ?
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <Price pricedata={impexprice.prices} supp={impexprice.supp} />
            </Grid>
            : null}
        </Grid>

      </div >
    </>
  );

}

export default Product;
