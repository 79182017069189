import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Product from './components/Product.js';
import Login from './components/login.js';
import Settings from './components/settings.js';

import Searchbar from './components/searchBar.js';

// import Home from './components/home.js';
// import Button from '@material-ui/core/Button';

import { BrowserRouter as Router, Switch, Link } from 'react-router-dom';

import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
import { removeUserSession } from './Utils/Common';

import { useSelector, useDispatch } from 'react-redux';
import { signOut, toggleDark } from './actions';


import logo from './images/logo.png';

import Avatar from '@material-ui/core/Avatar';
import { yellow } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import Tooltip from '@material-ui/core/Tooltip';

import './App.css';
const useStyles = makeStyles((theme) => ({

    menuButton: {
        marginRight: theme.spacing(1),
        color: "white",
    },
    title: {
        // flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            marginRight: 'auto',
            color: "white",
            display: 'flex',
        },
    },
    navMainLink: {
        color: theme.palette.common.white,
        textDecoration: 'none',
    },
    navLink: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
    },
    appBar: {
        backgroundColor: theme.palette.primary.dark,
    },
    yellow: {
        color: theme.palette.getContrastText(yellow[600]),
        backgroundColor: yellow[600],
    },
    search: {
        color: theme.palette.common.white,
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },

        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),

        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(0),
            marginRight: 'auto',
            // marginRight: theme.spacing(2),
            width: 'auto',
        },

    },
    darkToggle: {
        color: theme.palette.common.white,
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(0),

    },

}));




function App() {

    console.log('hi from App')
    const classes = useStyles();

    const isLogged = useSelector(state => state.isLogged);
    const isDark = useSelector(state => state.isDark);
    const dispatch = useDispatch();



    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [sterm, setSterm] = useState('');

    const handleLogout = () => {
        setAnchorEl(null);
        removeUserSession();
        dispatch(signOut());

    }

    const handleDarkon = () => {
        dispatch(toggleDark());

    }

    const handleMenu = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const searchItem = text => {
        setSterm({ text });

    }


    return (


        <div className={isDark ? "darkRoot" : "lightRoot"} >

            <Router>
                <AppBar position="sticky" className={classes.appBar}>
                    <Toolbar>
                        {/* <Box component="span"><img src={logo} height="40" alt="Logo" /> &nbsp;</Box> */}
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h5" className={classes.title}>
                            <Link className={classes.navMainLink} to="/">
                                Pricing
                            </Link>
                        </Typography>

                        {isLogged ?

                            <div className={classes.search}>
                                <Searchbar searchItem={searchItem} />
                            </div>
                            : null}
                        <Tooltip title="Toggle Dark/Light theme" >
                            {isDark ?
                                <IconButton
                                    className={classes.darkToggle}
                                    aria-label="Toggle Dark/Light theme"
                                    onClick={handleDarkon}
                                >
                                    <Brightness4Icon fontSize="large" />
                                </IconButton>
                                :
                                <IconButton
                                    className={classes.darkToggle}
                                    aria-label="Toggle Dark/Light theme"
                                    onClick={handleDarkon}
                                >
                                    <BrightnessHighIcon fontSize="large" />
                                </IconButton>
                            }
                        </Tooltip>
                        {isLogged ?
                            <Tooltip title="Account" >
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit">
                                    <Avatar className={classes.yellow}>H</Avatar>
                                </IconButton>
                            </Tooltip>

                            : null}
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <Link className={classes.navLink} to="/settings">
                                <MenuItem onClick={handleClose}>Settings</MenuItem>
                            </Link>
                            <Link className={classes.navLink} to="/login">
                                <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
                            </Link>
                        </Menu>

                        {/* <img src={logo} height="40" alt="Logo" /> */}
                    </Toolbar>

                </AppBar>
                <Switch>
                    {/* <Route exact path="/" component={Home} /> */}
                    <PrivateRoute exact path="/" component={Product} sterm={sterm} />
                    <PublicRoute path="/login" component={Login} />
                    <PrivateRoute path="/settings" component={Settings} />
                </Switch>
            </Router >
            {/* </div> */}
            {/* </div> */}
        </ div>
    )
}

export default App
