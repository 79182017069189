import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableRow from '@material-ui/core/TableRow';



const StyledTableCell = withStyles((theme) => ({
    head: {
        // backgroundColor: theme.palette.common.white,

        // backgroundColor: '#c9c9c9',
        color: theme.palette.text.primary,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    root: {

        padding: theme.spacing(1),


    },
    paper: {
        // padding: theme.spacing(2),
        // textAlign: 'center',
        color: theme.palette.text.secondary,


    },
    paper_title: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,

    },
    container: {
        maxHeight: 440,
    },
}));



function Sales({ sales }) {

    const classes = useStyles();

    return (

        <div className={classes.root}>

            <Paper square={true} elevation={2} className={classes.paper}>
                <Paper square={true} elevation={0} className={classes.paper_title}>

                    Sales History
                </Paper>
                <TableContainer className={classes.container}>
                    <Table stickyHeader>

                        <TableHead >
                            <TableRow>
                                <StyledTableCell>Transaction Date</StyledTableCell>
                                <StyledTableCell align="left">Sold To</StyledTableCell>
                                <StyledTableCell align="right">Quantity</StyledTableCell>
                                <StyledTableCell align="left">UOM</StyledTableCell>
                                <StyledTableCell align="left">Currency</StyledTableCell>
                                <StyledTableCell align="right">Rate</StyledTableCell>
                                <StyledTableCell align="right">Amount</StyledTableCell>
                                <StyledTableCell align="left">Sold By</StyledTableCell>
                                <StyledTableCell align="left">Order No.</StyledTableCell>
                                <StyledTableCell align="left">Ref. Doc. No.</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {sales.map((saleitem) => (

                                <TableRow hover={true} key={saleitem.order_no}>
                                    <TableCell>{saleitem.transaction_date}</TableCell>
                                    <TableCell>{saleitem.customer}</TableCell>
                                    <TableCell align="right">{saleitem.quantity}</TableCell>
                                    <TableCell>{saleitem.uom}</TableCell>
                                    <TableCell>{saleitem.currency}</TableCell>
                                    <TableCell align="right">{saleitem.rate}</TableCell>
                                    <TableCell align="right">{saleitem.amount}</TableCell>
                                    <TableCell>{saleitem.company}</TableCell>
                                    <TableCell>{saleitem.order_no}</TableCell>
                                    <TableCell>{saleitem.po_no}</TableCell>
                                </TableRow>

                            ))
                            }
                        </TableBody>
                    </Table >
                </TableContainer>
            </Paper>

        </div>

    )
}

export default Sales
