import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { useSelector } from 'react-redux';
import '.././App.css';

import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // paddingTop: theme.spacing(1),
        // paddingLeft: theme.spacing(1),
        // paddingRight: theme.spacing(1),
        // marginBottom: theme.spacing(2),
        padding: theme.spacing(1),


    },

    paper: {

        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),

        // textAlign: 'center',
        color: theme.palette.text.primary,


    },
    paperSvg: {

        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: 'center',

        // textAlign: 'center',
        // color: theme.palette.text.primary,
        // backgroundColor: theme.palette.common.white,

    },
    paper_title: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,

        alignItems: 'left',

    },

}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        // backgroundColor: theme.palette.common.black,
        // color: theme.palette.common.white,
        // backgroundColor: '#c9c9c9',
        color: theme.palette.text.primary,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
function Moldata({ mol, token }) {

    const classes = useStyles();

    const isDark = useSelector(state => state.isDark);

    const [itemstock, setItemstock] = useState([]);

    let apiURL = process.env.REACT_APP_API_URL;
    if (apiURL === undefined) {
        apiURL = "https://priceapi.hetcyc.com";
    }


    const get_stock = async (catid, token) => {
        let url = `/api/get_stock/${catid}?token=${token}`;
        url = apiURL.concat(url);

        await axios.get(url,

        ).then(response =>
            response.data).then(data => {

                setItemstock(data)

            });
    }

    useEffect(() => {
        get_stock(mol.id, token);
    }, [mol.id]);
    return (
        <div className={classes.root}>
            {/* <Grid container direction="column" > */}
            {/* <Grid item > */}
            <Paper square={true} elevation={2} key={mol.id} className={classes.paper0}>
                <Paper square={true} elevation={0} align='left' className={classes.paper_title}>
                    Details
                </Paper>
                <Grid container justify="space-evenly" alignItems="flex-start">


                    <Grid item xl={3} lg={3} md={5} sm={11} xs={11}>
                        <Paper square={true} variant="outlined" className={classes.paperSvg}>
                            <div className={isDark ? "svgcell" : "null"} dangerouslySetInnerHTML={{ __html: mol.svg }} />
                        </Paper>
                    </Grid>


                    <Grid item xl={5} lg={5} md={6} sm={11} xs={11}>
                        <Paper square={true} variant="outlined" className={classes.paper}>
                            <TableContainer>
                                <Table size="small">


                                    <TableBody>
                                        <TableRow>
                                            <TableCell variant="head" style={{ width: '20%' }} >Catalog ID</TableCell>
                                            <TableCell>{mol.id}</TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">Name</TableCell>
                                            <TableCell>{mol.mol_name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">CAS No</TableCell>
                                            <TableCell>{mol.cas_no}</TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">MF</TableCell>
                                            <TableCell><span dangerouslySetInnerHTML={{ __html: mol.mf }} /></TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">FW</TableCell>
                                            <TableCell>{mol.fw}</TableCell>
                                        </TableRow>
                                        {mol.purity ?
                                            <TableRow>
                                                <TableCell variant="head">Purity</TableCell>
                                                <TableCell>{mol.purity}</TableCell>
                                            </TableRow>
                                            : null}

                                        {mol.mp ?
                                            <TableRow>
                                                <TableCell variant="head">MP</TableCell>
                                                <TableCell>{mol.mp} &deg;C</TableCell>
                                            </TableRow>
                                            : null}

                                        {mol.bp ?
                                            <TableRow>
                                                <TableCell variant="head">BP</TableCell>
                                                <TableCell>{mol.bp} &deg;C</TableCell>
                                            </TableRow>
                                            : null}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>

                    <Grid item xl={3} lg={3} md={11} sm={11} xs={11}>
                        <Paper square={true} variant="outlined" className={classes.paper}>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow >
                                        <StyledTableCell align="center">Quantity</StyledTableCell>
                                        <StyledTableCell align="center">Price</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mol.p_250mg ?
                                        <TableRow>
                                            <TableCell align="center">250mg</TableCell>
                                            <TableCell align="center">{mol.p_250mg} USD</TableCell>
                                        </TableRow>
                                        : null}
                                    {mol.p_500mg ? <TableRow>
                                        <TableCell align="center">500mg</TableCell>
                                        <TableCell align="center">{mol.p_500mg} USD</TableCell>
                                    </TableRow> : null}
                                    {mol.p_1g ?
                                        <TableRow>
                                            <TableCell align="center">1g</TableCell>
                                            <TableCell align="center">{mol.p_1g} USD</TableCell>
                                        </TableRow>
                                        : null}
                                    {mol.p_5g ?
                                        <TableRow>
                                            <TableCell align="center">5g</TableCell>
                                            <TableCell align="center">{mol.p_5g} USD</TableCell>
                                        </TableRow>
                                        : null}
                                    {mol.p_25g ?
                                        <TableRow>
                                            <TableCell align="center">25g</TableCell>
                                            <TableCell align="center">{mol.p_25g} USD</TableCell>
                                        </TableRow>
                                        : null}
                                    {mol.p_100g ?
                                        <TableRow>
                                            <TableCell align="center">100g</TableCell>
                                            <TableCell align="center">{mol.p_100g} USD</TableCell>
                                        </TableRow>
                                        : null}
                                    {mol.p_500g ?
                                        <TableRow>
                                            <TableCell align="center">500g</TableCell>
                                            <TableCell align="center">{mol.p_500g} USD</TableCell>
                                        </TableRow>
                                        : null}
                                </TableBody>
                            </Table>

                        </Paper>
                        {itemstock.length ?
                            <Paper square={true} variant="outlined" className={classes.paper}>
                                <TableContainer>
                                    <Table size="small" stickyHeader>
                                        <TableHead>
                                            <TableRow >
                                                <StyledTableCell >Available Stock</StyledTableCell>
                                                <StyledTableCell > </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {itemstock.map((stock) => (
                                                <TableRow key={stock.warehouse}>
                                                    <TableCell >{stock.warehouse}</TableCell>
                                                    <TableCell >{stock.projected_qty} {stock.stock_uom} </TableCell>


                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            : null}
                    </Grid>

                </Grid>

            </Paper>
            {/* </Grid> */}
            {/* </Grid> */}
        </div >

    );
}

export default Moldata;