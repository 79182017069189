import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { createStore } from 'redux';
import allReducers from './reducers';
import { Provider } from 'react-redux';
import { useSelector } from 'react-redux';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';


Sentry.init({
  dsn: "https://b9aaef560f9e47bc96f31a173e90b189@o419509.ingest.sentry.io/5659180",

});




const store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);




const AppWrapper = () => {


  return (
    <Provider store={store}>

      <MainApp />

    </Provider>
  )

}

const MainApp = () => {

  const isDark = useSelector(state => state.isDark);

  const theme = createMuiTheme({

    palette: {
      type: isDark ? 'dark' : 'light',
      primary: {
        light: '#52c7b8',
        main: '#009688',
        dark: '#00675b',
        contrastText: '#fffff',

      },
      secondary: {
        light: '#ffff6b',
        main: '#fdd835',
        dark: '#c6a700',
        contrastText: '#00000',

      },
    },

  });

  return (

    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>

  )

}

ReactDOM.render(
  // <React.StrictMode>

  <AppWrapper />,
  // </React.StrictMode>,
  document.getElementById('root')
);

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
