import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableRow from '@material-ui/core/TableRow';




const useStyles = makeStyles((theme) => ({
    root: {

        padding: theme.spacing(1),

    },
    paper: {
        marginTop: theme.spacing(1),
        // textAlign: 'center',
        color: theme.palette.text.primary,

    },
    paper_title: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        alignItems: 'left',

    },

}));

function Price({ pricedata, supp }) {

    const classes = useStyles();




    return (
        <div className={classes.root}>
            <Paper square={true} elevation={2} className={classes.paper}>
                <Paper square={true} elevation={0} align='left' className={classes.paper_title}>
                    {supp}

                </Paper>
                <Table >
                    <TableBody>
                        {pricedata.map((price) => (




                            <TableRow key={price.Price}>

                                <TableCell align="center">{price.Quantity}{price.UOM}</TableCell>
                                <TableCell align="center">{price.Price}&nbsp;{price.Currency}</TableCell>
                            </TableRow>


                        ))
                        }
                    </TableBody>
                </Table >

            </Paper>
        </div>
    )
}

export default Price;
