import counterReducer from './counter';
import loggedReducer from './isLogged';
import darkReducer from './isDark';
import { combineReducers } from 'redux';

const allReducers = combineReducers({
    counter: counterReducer,
    isLogged: loggedReducer,
    isDark: darkReducer,
})

export default allReducers;
