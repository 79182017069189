import React from 'react';
import { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import axios from 'axios';
import { setUserSession } from '.././Utils/Common.js';

import { useDispatch } from 'react-redux';
import { signIn } from '.././actions';
import { Paper } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: theme.spacing(12),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Login(props) {
    const classes = useStyles();


    const dispatch = useDispatch();




    let apiURL = process.env.REACT_APP_API_URL;
    if (apiURL === undefined) {
        apiURL = "https://priceapi.hetcyc.com";
    }


    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);


    const get_token = (user, password) => {
        let url = `/api/login`;
        url = apiURL.concat(url);


        const authToken = Buffer.from(`${user}:${password}`, 'utf8').toString('base64')
        axios.get(url, {
            headers: {
                'Authorization': `Basic ${authToken}`
            }
        }

        ).then(response => {
            setUserSession(response.data.token, response.data.user);
            props.history.push('/');
            dispatch(signIn());

        }).catch(error => {
            if (error.response.status === 401) setError('Wrong Credentials');
            else setError("Something went wrong. Please try again later.");
        });

    }

    const handleSubmit = e => {
        e.preventDefault();
        if (!user) return;

        get_token(user, password);


        setUser('');
        setPassword('');
    }



    return (
        <Paper>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    {/* <AuthContext.Consumer>
                    {({ loggedIn, setLoggedIn }) => (
                        setLoggedIn(false)
                    )}
                </AuthContext.Consumer> */}
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon color="primary" />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="user"
                            label="Username"
                            name="user"
                            autoComplete="email"
                            autoFocus
                            value={user.toLowerCase().trim()} onChange={e => setUser(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password} onChange={e => setPassword(e.target.value)}
                        />
                        {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className={classes.submit}
                            onClick={e => handleSubmit(e)}
                        >
                            Sign In
                    </Button>
                        {error && <Typography align="center" variant="h6" color="error">Wrong Credentials</Typography>}<br />

                    </form>
                </div>

            </Container>
        </Paper>
    );
}

export default Login;
