import React from 'react'
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({


    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '30ch',
            '&:focus': {
                width: '40ch',
            },
        },
    },
}));

function Searchbar({ searchItem }) {

    const classes = useStyles();

    const [sterm, setSterm] = useState('');
    console.log('hi from searchBar')
    const handleSubmit = e => {
        e.preventDefault();
        // console.log(sterm)
        if (!sterm) return;
        searchItem(sterm);
        setSterm('');
    }

    return (

        <form onSubmit={e => handleSubmit(e)} >

            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder="Search by CAS or Catalog ID.."
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={sterm} onChange={e => setSterm(e.target.value)}
            />

        </form>

    )
}

export default Searchbar;
